<template>
  <div style="padding:10px 0;">
    <div>
      <van-image
        round
        width="1.5rem"
        height="1.5rem"
        :src="v.avatar"
        style="vertical-align:middle;margin:0 5px 0 0;"
      />
      <span>
        <small>{{ v.name !== '' ? v.name.substring(0, 2) + '**' : '****' }}</small>
        <small style="float:right;color:#999;margin:3px 0 0 0;">{{ v.time.substring(0, 10) }}</small>
      </span>
    </div>
    <div style="padding:5px 0;">
      <p>
        <small style="">
          {{ v.content }}
        </small>
      </p>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props) {
    const state = reactive({
      show: false,
      v: props.item
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
