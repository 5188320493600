<template>
  <van-cell :title="`评论 ${comment.total}条`" :value="`好评率 ${(comment.favorableRating * 100).toFixed(1)}%`" is-link style="margin:7px 0 0 0;" />
  <div style="padding:15px;background:#fff;">
    <van-tag round size="medium" :type="checked === 'GOOD' ? 'danger' : 'default'" @click="onChange('GOOD')">好评（{{ comment.good }}）</van-tag>&nbsp;
    <van-tag round size="medium" :type="checked === 'GENERAL' ? 'danger' : 'default'" @click="onChange('GENERAL')">中评（{{ comment.general }}）</van-tag>&nbsp;
    <van-tag round size="medium" :type="checked === 'BAD' ? 'danger' : 'default'" @click="onChange('BAD')">差评（{{ comment.bad }}）</van-tag>&nbsp;
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getData"
    >
      <template v-for="v in items" :key="v">
        <comment-item :item="v" />
      </template>
    </van-list>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import CommentItem from '../../components/CommentItem'

export default {
  components: {
    CommentItem
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      items: [],
      title: '',
      productId: useRoute.query.productId,
      v1: 0,
      v2: 0,
      comment: {
        bad: 0,
        favorableRating: 1,
        general: 1,
        good: 0,
        total: 1,
        items: []
      },
      checked: undefined
    })
    const onChange = (v) => {
      state.checked = v
      reset()
    }
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const getData = () => {
      post('/order.list', {
        productId: state.productId,
        comment: true,
        commentGrade: state.checked
      }).then(res => {
        for (const v of res.data.content) {
          state.items.push({
            avatar: v.buyer.avatar,
            name: v.buyer.name,
            content: v.commentContent,
            time: v.commentTime
          })
        }
        // state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const sum = () => {
      post('/order.sumComment', {
        productId: state.productId
      }).then(res => {
        console.log(res)
        state.comment.favorableRating = res.data.favorableRating
        state.comment.general = res.data.general
        state.comment.good = res.data.good
        state.comment.bad = res.data.bad
        state.comment.total = res.data.total
      })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onChange
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
